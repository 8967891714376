<template>
  <div>
    <div class="margin-1">
      Erreur réseau lors de l'envoi du signalement.
    </div>
    <div class="margin-1">
      Votre signalement devra être envoyé au serveur quand vous aurez de nouveau accès à internet.
    </div>
    <div class="margin-1">
      Veuillez à ce moment là, cliquer sur le bouton "Envoyer", sur la page principale du projet 
    </div>
    <router-link
      :to="{
        name: 'project_detail',
        params: { slug: $route.params.slug },
      }"
      class="ui positive left labeled icon button margin-1"
    >
      <i
        class="arrow left icon"
        aria-hidden="true"
      />Retour au projet
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'FeatureOffline',
};
</script>

<style scoped>
.margin-1 {
  margin: 1rem;
}
</style>
